<template>
  <div class="schedaSegnalazione" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md8 lg6>
          <v-toolbar color="secondary">
            <v-toolbar-title class="white--text">
                Segnalazione <template v-if="numero"> - <b>#{{ numero }}</b></template>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small icon @click="remove_attivita()" color="white" v-if="isMaster">
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
            <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3">
              <v-icon small>fas fa-edit</v-icon>
            </v-btn>
            <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
              <v-icon small>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card outlined>
              <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                <v-container>
                  <v-layout column>
                    <div style="display: inline-flex;" class="mb-2">
                      Data Creazione: <b class="ml-3">{{ formatDate(data_creazione) }}</b>
                    </div>
                    <div style="display: inline-flex;" class="mb-2" v-if="isMaster">
                      Creatore: <b class="ml-3">{{ da_cliente ? 'Cliente' : 'Admin' }}</b>
                    </div>
                    <v-text-field
                        autocomplete="off"
                        :readonly="!isEditing"
                        label="Titolo"
                        v-model="titolo"
                        required
                        :rules="testoRules"
                    ></v-text-field>
                    <v-textarea
                        autocomplete="off"
                        :readonly="!isEditing"
                        label="Note"
                        v-model="note"
                        hide-details="auto"
                        :class="da_cliente || !isMaster ? 'mb-5' : ''"
                        outlined
                        auto-grow
                        rows="1"
                    ></v-textarea>
                    <v-container fluid v-if="!da_cliente && isMaster">
                      <v-row>
                        <v-col cols="12" sm="4" class="py-0">
                          <v-checkbox label="Informa Utente" dense disabled v-model="informaUtente"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-select
                        :readonly="!isEditing"
                        :items="lista_clienti"
                        v-if="isMaster"
                        v-model="cliente"
                        required
                        outlined
                        :rules="requiredRules"
                        label="Cliente"
                        class="mb-0"
                        no-data-text="Nessun Cliente"
                        item-text="nominativo1"
                        item-value="_id"
                    ></v-select>
                    <v-select
                        :readonly="!isEditing"
                        :items="lista_stabilimenti"
                        v-model="stabilimento"
                        required
                        outlined
                        :rules="requiredRules"
                        label="Stabilimento"
                        class="mb-0"
                        no-data-text="Nessuno Stabilimento"
                        item-text="indirizzo"
                        item-value="_id"
                    ></v-select>

                      <v-col cols="12" offset="0" sm="6" offset-sm="3" class="py-0">
                        <VueUploadMultipleImage 
                          :showEdit="isEditing"
                          :showDelete="isEditing"
                          :showAdd="isEditing"
                          :disabled="false"
                          dragText="Carica Immagini"
                          browseText="Drag & Drop"
                          dropText="Rilascia Qui"
                          accept="image/gif,image/jpeg,image/png,image/bmp,image/jpg,image/*"
                          class="uploaderFix"
                          @upload-success="imageListChanged"
                          @before-remove="removeImage"
                          @edit-image="imageListChanged"
                          :data-images="immagini"
                        />
                      </v-col>

                    <ListaEvacuatori :lista_evacuatori="lista_evacuatori" v-if="lista_evacuatori && lista_evacuatori.length" :id_stabilimento="stabilimento" :hideFooter="true" :actionScheda="false"/>

                    <v-col cols="12" sm="12" md="5" class="pt-0"> 
                      <v-btn v-if="lista_evacuatori && lista_evacuatori.length" color="primary" width="100%" @click="isViewingEvacuatoreMappa = !isViewingEvacuatoreMappa">
                        <v-icon small class="mr-2">fas fa-eye</v-icon>
                        Vedi nella mappa
                      </v-btn> 
                    </v-col>
                  </v-layout>
                </v-container>
              </v-form>
              <v-card-actions>
                  <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                    <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && !formValid && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn> 
                  </v-col>
              </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
    <v-dialog 
      v-model="isViewingEvacuatoreMappa"
      max-width="90vw"
    >
      <MappaEvacuatori :id_stabilimento="stabilimento" :list_selected="lista_evacuatori" v-if="stabilimento && isViewingEvacuatoreMappa" @evacuatoriSelected="isViewingEvacuatoreMappa = false"/>
    </v-dialog>
  </div>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiSegnalazioni from '@/js/pages/segnalazioni';
import { testoRules, requiredRules } from '@/js/validationRules';
const NewDDT = () => import('@/components/NewDDT');
import { momentDate } from '@/js/formatDate';
const ListaEvacuatori = () => import('@/components/Lista_evacuatori');
const MappaEvacuatori = () => import('@/components/Mappa_evacuatori');
import VueUploadMultipleImage from 'vue-upload-multiple-image';

export default {
  name: 'schedaSegnalazione',
  components: {
    NewDDT,
    ListaEvacuatori,
    MappaEvacuatori,
    VueUploadMultipleImage,
  },
  data() {
    return {
      token: this.$cookies.get('token'),
      ruolo: this.$store.state.ruolo,
      id: this.$route.query._id,
      isEditing: false,
      numero: '',
      titolo: '',
      note: '',
      informaUtente: false,
      da_cliente: false,
      cliente: undefined,
      stabilimento: undefined,
      lista_clienti: [],
      lista_stabilimenti: [],
      lista_evacuatori: [],
      formValid: false,
      testoRules: testoRules,
      requiredRules: requiredRules,
      loading: false,
      lista_tipi_attivita: ['Manutenzione', 'Posa', 'Controllo', 'Altro'],
      data_creazione: '',
      isViewingEvacuatoreMappa: false,
      isMaster: false,
      immagini: [],
    }
  },
  watch: {
    async cliente() {
      this.lista_stabilimenti = await apiStabilimenti.getAll(this.token, this.cliente);
      if(
        this.lista_stabilimenti &&
        this.lista_stabilimenti.length === 1
      ) this.stabilimento = this.lista_stabilimenti[0]['_id'];
    },
  },
  async created() {
    if(this.ruolo == 'Cliente') {
      this.da_cliente = false;
      this.isMaster = false;
    } else this.isMaster = true;

    if(this.ruolo == 'Partner') this.lista_clienti = await apiUtenti.getAllClientPartner(this.token, 'Baggi');
    else this.lista_clienti = await apiUtenti.getAll(this.token, 'Cliente');
    
    if(!this.id) this.id = this.$route.query._id;
    
    let segnalazione = await apiSegnalazioni.getOne(this.id);
    this.numero = segnalazione.numero || '';
    this.titolo = segnalazione.titolo || '';
    this.note = segnalazione.note || '';
    this.cliente = segnalazione.id_cliente;
    this.stabilimento = segnalazione.id_stabilimento;
    this.lista_evacuatori = segnalazione.id_evacuatori;
    if(segnalazione.da_cliente) this.da_cliente = segnalazione.da_cliente;
    if(segnalazione.informa_utente) this.informaUtente = true;
    this.data_creazione = new Date(segnalazione.data_creazione).toISOString().substr(0, 10) || '';

    if(
      segnalazione &&
      segnalazione.images && 
      segnalazione.images.length
    ) {
      for (const immagine of segnalazione.images) {
        let nomeFile = '';
        nomeFile = immagine.substring(immagine.lastIndexOf("/")+1);
        //nomeFile = nomeFile.substring(0, nomeFile.lastIndexOf("_")) + nomeFile.substring(nomeFile.lastIndexOf("."));
        this.immagini.push({
          default: 0,
          highlight: 0,
          path: 'https://app.lacelle.it:5000/'+immagine,
          finalName: immagine,
          name: nomeFile
        });
      }
    }
  },
  methods: {
    async reloadDDTdata() {
      let attivita = await apiSegnalazioni.getOne(this.id);
      this.idDDT = attivita.ddt && attivita.ddt[0] ? attivita.ddt[0]._id : undefined;
    },
    async submit(){
      if(this.isEditing){
        this.loading = true;
        if(
          this.$refs.form_data.validate() &&
          this.formValid
        ){
          let data = {
            _id: this.id,
            titolo: this.titolo,
            note: this.note,
            id_cliente: this.cliente,
            id_stabilimento: this.stabilimento,
          };
          await apiSegnalazioni.updateOne(this.token, data);
          let formData = new FormData();
          for (const immagine of this.immagini) {
            if(immagine.path.substring(0,25) == 'https://app.lacelle.it:5000') formData.append("immagini", new Blob(), immagine.finalName);
            else {
              let finalBlob = await fetch(immagine.path).then(res => res.blob());
              formData.append("immagini", finalBlob, immagine.name);
            }
          }
          
          apiSegnalazioni.sendFileImmagini(this.token, this.id, formData);
          this.$router.back();
        }
        this.loading = false;
      } else this.$router.back();
    },
    async remove_attivita(){
      if(confirm("Eliminare l'attivita?")){
        let data = { _id: this.id };
        await apiSegnalazioni.deleteOne(this.token, data);
        this.$router.back();
      }
    },
    formatDate(date) {
      return momentDate(date);
    },
    async inviaImmagine() {
      if (this.imageUploaded) {
        let formData = new FormData();
        formData.append("immagine", this.imageUploaded);
        await apiProdotti.uploadImage(this.token, formData, this.id);
        await this.loadData();
        this.imageUploaded = undefined;
      }
    },
    imageListChanged(formData, index, fileList) {
      this.immagini = fileList;
    },
    async removeImage(index, done, fileList) {
      var r = confirm("Rimuovere l'immagine?");
      if (r == true) {
        await done();
        this.immagini = fileList;
      }
    },
  },
}
</script>
